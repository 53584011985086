// Core React
import React from "react"
import Layout from "@components/layout/Layout/Layout"
import Artists from "@components/partials/Artists"

// Render
const ArtistsPage = () => (
  <Layout
    title="Artists || WhelpS NFT"
    contentClassName="px-0 max-w-full bg-secondary-whelps"
    page="artists"
  >
    <Artists />
  </Layout>
)
export default ArtistsPage

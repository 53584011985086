import React from "react"

import { artistsData, derivateArtistsData } from '@util/whelpsData'

export default function Wallet() {
  return (
    <>
      <div className="py-12 bg-secondary-whelps md:py-20">
        <div className="container mx-auto">
          <div className="my-16 text-2xl tracking-wider text-center font-lemon text-primary-whelps md:text-4xl">
            Dragon Mystics
          </div>
          <div className="grid max-w-5xl grid-cols-1 gap-5 mx-auto md:grid-cols-2">
            <div className="grid-item">
              <div className="my-16 text-xl tracking-wider text-center font-lemon text-primary-whelps md:text-2xl">
                Whelps Artist
              </div>
              <a href="https://twitter.com/Yantri_Art" target="_blank">
                <img src="/artists/whelps-artist.png" alt="" className="max-w-xs mx-auto" />
                <div className="mx-auto mt-6 text-lg leading-8 text-center font-roboto-bold tmax-w-xl text-light-whelps">
                  Yantri
                </div>
                <div className="mx-auto text-lg leading-8 text-center font-roboto-light tmax-w-xl text-light-whelps">
                  @Yantri_Art
                </div>
              </a>
            </div>
            <div className="grid-item">
              <div className="my-16 text-xl tracking-wider text-center font-lemon text-primary-whelps md:text-2xl">
                Backgrounds Artist
              </div>
              <a href="https://twitter.com/engwind_art" target="_blank">
                <img src="/artists/background-artist.png" alt="" className="max-w-xs mx-auto" />
                <div className="mx-auto mt-6 text-lg leading-8 text-center font-roboto-bold tmax-w-xl text-light-whelps">
                  ΞNGWIND
                </div>
                <div className="mx-auto text-lg leading-8 text-center font-roboto-light tmax-w-xl text-light-whelps">
                  @engwind_art
                </div>
              </a>
            </div>
          </div>

          <div>
            <img src="/line.png" className="mx-auto my-16" alt="" width="200" />
          </div>

          <div className="my-16 text-2xl tracking-wider text-center font-lemon text-primary-whelps md:text-4xl">
            Dragon Guests
          </div>
          <div className="my-8 text-xl tracking-wider text-center font-lemon text-primary-whelps md:text-2xl">
            Sevyn
          </div>
          <a href="https://twitter.com/KonstadAlex" target="_blank">
            <img src="/artists/sevyn.png" alt="" className="max-w-xs mx-auto" width="200" />
            <div className="mx-auto mt-6 text-lg leading-8 text-center font-roboto-bold tmax-w-xl text-light-whelps">
              Alex Konstad
            </div>
            <div className="mx-auto text-lg leading-8 text-center font-roboto-light tmax-w-xl text-light-whelps">
              @KonstadAlex
            </div>
          </a>
          
          <div className="mt-20 mb-12 text-xl tracking-wider text-center font-lemon text-primary-whelps md:text-2xl">
            Elder Dragons
          </div>

          <div className="grid max-w-5xl grid-cols-1 gap-5 mx-auto md:grid-cols-3">
            {
              artistsData.map((dt, idx) => (
                <div className="mt-5 grid-item" key={idx}>
                  <a href={'https://twitter.com/' + dt.artProfile} target="_blank">
                    <img src={dt.artImg} alt="" className="mx-auto" width="200" />
                    <div className="mx-auto mt-6 text-lg leading-8 text-center font-roboto-bold tmax-w-xl text-light-whelps">
                      {dt.artName}
                    </div>
                    <div className="mx-auto text-lg leading-8 text-center font-roboto-light tmax-w-xl text-light-whelps">
                      @{dt.artProfile}
                    </div>
                  </a>
                </div>
              ))
            }
          </div>

          <div>
            <img src="/line.png" className="mx-auto my-16" width="200" alt="" />
          </div>

          <div className="my-16 text-2xl tracking-wider text-center font-lemon text-primary-whelps md:text-4xl">
            Dragon Stylists
          </div>

          <div className="grid grid-cols-1 gap-5 mx-auto md:grid-cols-5 pb-6">
            {
              derivateArtistsData.map((dt, idx) => (
                <div className="mt-5 grid-item" key={idx}>
                  <a href={'https://twitter.com/' + dt.deArtistsProfile} target="_blank">
                    <img src={dt.deArtistsImg} alt="" className="mx-auto" width="160" />
                    <div className="mx-auto mt-6 text-lg leading-8 text-center font-roboto-bold tmax-w-xl text-light-whelps">
                      {dt.deArtistsName}
                    </div>
                    <div className="mx-auto text-lg leading-8 text-center font-roboto-light tmax-w-xl text-light-whelps">
                      @{dt.deArtistsProfile}
                    </div>
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  )
}
